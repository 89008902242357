import { bodyBg } from "../constants/styleTokens"
import styled from "styled-components"
import media from "styled-media-query"

export const Text = styled.p`
  font-family: Lato;
  color: #f1f1f1;
  font-size: 18px;
  line-height: 1.5em;
  text-transform: lowercase;
  font-weight: 400;
  font-weight: regular;

  ${props => props.black && `color: #171717;`}

  ${media.greaterThan("large")`
    font-size: 26px;

    
  `}
`

export const SectionTitle = styled.h1`
  font-family: Squada One;
  color: #1eadef;
  text-transform: lowercase;
  line-height: 0.82em;
  font-size: 52px;

  ${media.greaterThan("large")`
    font-size: 100px;
  `}
`

export const Main = styled.main`
  background-color: ${bodyBg};
  overflow-x: hidden;
`

export const Container = styled.div`
  width: calc(100% - 40px);
  heigh: 100%;
  max-width: 335px;
  display: block;
  margin: 0 auto;

  ${media.greaterThan("large")`
    width: 100%;
    max-width: ${props => (props.title ? "1135px" : "900px")};
  ${props =>
    props.middle &&
    `
      max-width: 920px;
    `}
  `}
`

export const Button = styled.button`
  background-color: ${props => (props.yellow ? "#FFBA46" : "#1eadef")};
  padding: 21px 46px 27px;
  border-radius: 6px;
  border: none;
  border-bottom: solid 8px ${props => (props.yellow ? "#C8810B" : "#1893CC")};
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
  font-weight: bold;
  text-transform: lowercase;
  max-width: 325px;
 ${props =>
   props.social &&
   `
    padding: 0;
    width: 70px;
    height: 60px; 
  `}

  ${media.greaterThan("large")`
${props =>
  props.social &&
  `
    width: 94px;
    height: 80px; 
  `}

  `}

  ${props =>
    props.white &&
    `
    background-color: #f1f1f1;
    border-bottom: solid 8px #c4c4c4;
   
  `}

  > .text {
    font-family: Lato;
    font-size: 24px;
    color: #212121;
    z-index: 2;
    position: relative;
      transition: all .14s ease-in-out;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.yellow ? "#C8810B" : " #1893CC")};
    z-index: -1;
    transition: all .15s cubic-bezier(.05,.97,.55,.99);
    transform: translate3d(0, 100%, 0);
    ${props =>
      props.white &&
      `
      background-color: #c4c4c4;
    `}


  }

  &:hover {
    > .text {
      color: #f1f1f1;
      transition: all .28s ease-in-out;
 ${props =>
   props.white &&
   `
      color: #171717;
    `}
    }
    &:before {
      transform: translate3d(0, 0, 0);
      transition: all .3s cubic-bezier(.05,.97,.55,.99);
    }
    }
  }
`
